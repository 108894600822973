.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F1F1F1;
    padding: 15px;
    font-size: 14px;
    z-index: 1000;
  }
  
  .cookie-message {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .cookie-buttons button {
    margin: 0 10px;
    padding: 8px 16px;
    background-color: #507025;
    border: none;
    color: white;
    cursor: pointer;
    width: 120px;
    border-radius: 999px;
  }
  